'use strict';
import { app } from '@/main.js'

const PlantsService = {

    getPlants() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get('/masterData/plants')
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response, msg: error.response }) });
        });
    },
    getPlantById(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plant/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantByCode(code) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantCode/${code}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantsByCountry(country) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantCountry/${country}`)
                .then(response => { return resolve(response)})
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getCircuitsByPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantCircuit/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getLoadingLineByPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantLoadingLine/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getTerminalsByPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantTerminal/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getFullTerminal(terminal) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/fullterminal/${terminal}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getQueueByPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantQueue/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getFormByPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantForm/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantsTerminalByCountry() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantTerminalsByCountry`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantBySalesOrg(sales_org){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getPlantBySalesOrg/${sales_org}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data}) });
        });
    },
    getPlantSalesAreaByCountry(country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantSalesAreaByCountry/${country}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantParameters(plant){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/params/plant/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantParametersByCountry(country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/paramsByCountry/plant/${country}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantParameterByPlantCode(plantCode, param){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/params/plantCode/${plantCode}/${param}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantPermissions(user, country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/security/plantsAndPermissions/${user}/${country}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantShift(plant){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantshift/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantTour(plant){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantTour/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantUserBelongs(country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterdata/plantsUserBelongs/${country}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    plantsInClusterPlant(plant){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantsInClusterPlant/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantsByCluster(cluster){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantsByCluster/${cluster}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getFullPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/fullPlant/${plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantsByCode(plants) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/plantsByCode`, plants)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantHolidaysByYearRange(idPlant, lowerYear, upperYear) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/getPlantHolidaysByYearRange/${idPlant}`, { lowerYear, upperYear })
                .then((response) => {
                    return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    createPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlant', plant)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    createPlantLoadingLine(loadingLine) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlantLoadingLine', loadingLine)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    createPlantQueue(queue) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlantQueue', queue)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    createPlantCircuit(circuit) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlantCircuit', circuit)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    createPlantTerminal(terminal) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlantTerminal', terminal)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    createPlantForm(form) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlantForm', form)
                .then(response => { return resolve(response)})
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    createPlantShift(shift) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlantShift', shift)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    createPlantTour(tour) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createPlantTour', tour)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlant(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlant', plant)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantLoadingline(loadingline) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editLoadingLine', loadingline)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantQueue(queue) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantQueue', queue)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantTerminal(terminal) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantTerminal', terminal)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantCircuit(circuit) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantCircuit', circuit)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantForm(form) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantForm', form)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantParameters(parameters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantParameters', parameters)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantSalesArea(salesArea) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantSalesArea', salesArea)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantShift(shift) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantShift', shift)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantWorkingHours(hours) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantHours', hours)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantWorkingHours(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getPlantHours/${id}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantTour(tour) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantTour', tour)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    editPlantHolidays(id_plant, holidays) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantHolidays', { id_plant, holidays })
                .then(response => { return resolve(response) })
                .catch(error => {reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    deletePlantForm(form) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.delete(`/masterData/delPlantForm/${form.id_form}/${form.id_plant}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    //PLANT MOVEMENTS
    getPlantMovement(plant_code) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/plantMovements/${plant_code}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        });
    },
    getPlantFromSAP(plant_code) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getPlantFromMule/${plant_code}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        })
    },

    //EXTERNAL PLANTS
    getExternalPlants() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getExternalPlants`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        }) 
    },
    getExternalPlantByID( externalPlantId ) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getExternalPlantByID/${externalPlantId}`)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        }) 
    },
    saveExternalPlants( externalPlant ) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/addExternalPlant`, externalPlant)
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        }) 
    },
    getExternalPlantsByUserId(userId, countryId){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterdata/getExternalPlantsByUserId/${userId}/${countryId}`)
                .then(response => resolve(response))
                .catch(error => reject({ ret: error.response.status, msg: error.response.data }))
        })
    }
}

export default PlantsService;